import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        const element = document.querySelector(`#row${this.closestEventDate()}`);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - 150;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    closestEventDate() {
        return this.data.get('closestEvent');
    }
}
